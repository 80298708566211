<template>
  <div class="dashboard-header-wrapper" v-if="getUserAccount()">
    <div class="dashboard-head">
      <h1 class="dashboard-title">{{ $t('dashboard.title')}}</h1>
      <p class="member">{{ $t('dashboard.header.premium_member_since')}} {{ getUserAccount().created_at ? new Date(getUserAccount().created_at).getFullYear() : ''}}</p>
    </div>
    <div class="dashboard-header">
      <div class="personal-info">
        <p class="phone-number">{{ getUserAccount() ? getUserAccount().phone_number : getUserPhoneNumber() }}</p>
        <p class="email" v-if="getUserAccount().email_address">{{ getUserAccount().email_address }}</p>
      </div>
      <div class="range-selector">
        <date-picker v-model="range" range :placeholder="$t('dashboard.header.start_end')" :disabled-date="(range) => range >= new Date()" @change="rangeChanged()"></date-picker>
      </div>
    </div>
    <div class="submenu-buttons">
      <button :class="['submenu--item', {'active' : activeTab === 'invoices'}]" @click="$emit('tab', 'invoices')">{{ $t('dashboard.header.invoices')}}</button>
      <button :class="['submenu--item', {'active' : activeTab === 'subscriptions'}]" @click="$emit('tab', 'subscriptions')">{{ $t('funnel.subscription')}}</button>
    </div>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'DashboardHeader',
  props: {
    activeTab: String,
    modifier: {
      default: 'invoices',
      type: String
    }
  },
  components: {
    DatePicker
  },
  data() {
    return {
      range: null
    }
  },
  mounted() {
    this.setStartDate(null)
    this.setEndDate(null)
    this.fetchAccount()
  },
  methods: {
    ...mapActions([
      'fetchAccount'
    ]),
    ...mapGetters([
      'getUserAccount',
      'getUserPhoneNumber',
    ]),
    ...mapMutations([
      'setStartDate',
      'setEndDate'
    ]),
    rangeChanged() {
      this.setStartDate(this.range[0])
      this.setEndDate(this.range[1])
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/partials/_dashboard-header";

.mx-datepicker {
  .mx-input {
    color: #333;
    border-color: #B4B4BB !important;
  }
}
</style>