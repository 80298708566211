<template>
  <div>
    <div class="content-wrapper column" v-if="showSpinner">
      <Spinner/>
    </div>
    <div class="content-wrapper column" v-else>
      <div class="subscriptions-header padding-left-40" v-if="!backendError">
        <span>{{$t('funnel.subscription')}}</span>
        <span class="your-plan">{{$t('dashboard.subscriptions.your_plan')}}:</span>
      </div>
      <div class="flex-container" v-if="backendError">
        <div class="backend-error">
          <p>{{ $t('dashboard.backend_error') }}</p>
        </div>
      </div>
      <div class="flex-container" v-else>
        <div class="subscription">
          <h1>{{ getTime() }} {{$t('dashboard.subscriptions.premium')}}</h1>
          <p class="start-date"><b>{{$t('dashboard.subscriptions.user_since')}}:</b> {{ getUserAccount().created_at ? getDate(new Date(getUserAccount().created_at)) : ''}}</p>
          <p><b>{{ getStatus() ? $t('dashboard.subscriptions.renewal') : $t('dashboard.subscriptions.expiration')}} {{$t('dashboard.subscriptions.date')}}:</b> {{ getExpDate() }}</p>
          <p v-if="getStatus()">{{$t('dashboard.subscriptions.you_will_be_charged')}} <b v-if="isWeb()">${{getPrice()}} </b>{{ isWeb() ? $t('dashboard.subscriptions.through_braintree') : ''}}</p>
          <ul>
            <li>{{$t('funnel.unlimited_recordings')}}</li>
            <li>{{$t('funnel.no_time_limits')}}</li>
            <li>{{$t('funnel.no_per_minute')}}</li>
            <li>{{$t('funnel.cancel_at_anytime')}}</li>
            <li>{{$t('funnel.unlimited_transcription')}}</li>
          </ul>
        </div>
        <div class="subscriptions-actions">
          <a href="#" @click.prevent="confirmModal = true; activeAction = 'cancel';" class="error" v-if="isWeb() && getStatus()">{{$t('funnel.cancel')}} {{$t('funnel.subscription')}}</a>
          <p v-else-if="!getStatus()">{{ $t('dashboard.subscriptions.your_subscription_was_canceled') }}</p>
        </div>
      </div>
      <div class="information" v-if="!isWeb() && !backendError">
        <p>{{$t('dashboard.subscriptions.if_you_want')}}</p>
      </div>

      <Modal v-if="confirmModal" :className="'align-left'">
        <template v-slot:header>
          <div class="create-new-list">
            <h1>{{ activeAction === 'cancel' ? $t('dashboard.sidebar.cancel') : $t('dashboard.subscriptions.renew') }} {{ $t('dashboard.invoices.subscription') }}</h1>
            <hr>
            <p class="mobile-info" v-if="!isWeb()">{{ $t('dashboard.subscriptions.in_order_to') }} {{ activeAction === 'cancel' ? $t('dashboard.sidebar.cancel') : $t('dashboard.subscriptions.renew') }} {{ $t('dashboard.subscriptions.in_order_to_rest') }}</p>
            <div class="actions" v-if="isWeb()">
              <p @click="confirmModal = false">{{ $t('dashboard.sidebar.cancel') }}</p>
              <button class="create" @click.stop="cancelSubscriptionMethod()" v-if="activeAction === 'cancel'">{{ $t('dashboard.subscriptions.ok') }}</button>
            </div>
          </div>
        </template>
      </Modal>

      <Modal v-if="confirmLegal" @close="confirmLegal = false" @confirm="acceptUpdatedTerms()">
        <template v-slot:header>
          <p class="modal-copy-text" v-html="$t('funnel.we_updated_legal')"></p>
        </template>
      </Modal>

    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner'
import Modal from '@/components/ModalComponent'
import { mapActions, mapGetters } from 'vuex'
import i18n from '../i18n'
export default {
  name: 'Subscriptions',
  components: {
    Spinner,
    Modal
  },
  computed: {
    newLocale:() => {
      return i18n.locale
    }
  },
  data() {
    return {
      confirmModal: false,
      confirmLegal: false,
      showSpinner: true,
      activeAction: '',
      backendError: false
    }
  },
  mounted() {
    this.fetchSubscriptions().then(() => {
      this.getReceipt().then(() => {
        this.showSpinner = false
      })
    }).catch(() => this.showSpinner = false)
  },
  methods: {
    ...mapActions([
      'getPP',
      'getTerms',
      'cancelSubscription',
      'acceptTerms',
      'fetchSubscriptions',
      'getReceipt'
    ]),
    ...mapGetters([
      'getActiveSubscriptions',
      'getReceipts',
      'getUserAccount'
    ]),
    isWeb() {
      let activeSub = this.getActiveSubscriptions()[0]

      if(!activeSub) {
        this.backendError = true
        return
      }

      let skuu = activeSub.product_id
      if(skuu.indexOf('bt-trec') !== -1) {
        return true
      }

      return false
    },
    getTime() {
      let activeSub = this.getActiveSubscriptions()[0]

      if(!activeSub) {
        this.backendError = true
        return
      }

      let skuu = activeSub.product_id

      if(skuu.indexOf('yearly') !== -1) {
        return i18n._vm.messages[this.newLocale].funnel.annual
      } else if(skuu.indexOf('monthly') !== -1){
        return i18n._vm.messages[this.newLocale].funnel.monthly
      } else {
        return ''
      }
    },
    getPrice() {
      let activeSub = this.getActiveSubscriptions()[0]

      if(!activeSub) {
        this.backendError = true
        return
      }

      let skuu = activeSub.product_id

      if(!skuu) return

      let skuuArray = skuu.split('-')
      return skuuArray[skuuArray.length - 1].split("_").join(".")
    },
    getStatus() {
      let activeSub = this.getActiveSubscriptions()[0]

      if(!activeSub) {
        this.backendError = true
        return
      }

      let status = activeSub.status

      if(status === 'Active' || status === 'Past Due') {
        return true
      }
      return false
    },
    getExpDate() {
      let receipts = this.getReceipts()
      if(receipts && receipts.recordings) {
        let expDate = new Date(receipts.recordings.expiry)
        return this.getDate(expDate)
      } else {
        return 'expired'
      }
    },
    getDate(date) {
      return date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate())
    },
    addZero(n) {
      return (n < 10 ? '0' : '') + n
    },
    renewSubscriptionMethod() {
      this.resetData()
    },
    cancelSubscriptionMethod() {
      let subID = this.getActiveSubscriptions()[0].subscription_id

      if(!subID) return 

      this.showSpinner = true
      this.cancelSubscription(subID).then(() => {
        this.resetData()
      }).catch((statusCode) => {
        this.showSpinner = false
        if(statusCode === 451) {
          this.getPP()
          this.getTerms()
          this.confirmLegal = true
        }
      })
    },
    resetData() {
      this.showSpinner = false
      this.activeAction = ''
      this.confirmModal = false
    },
    acceptUpdatedTerms() {
      this.acceptTerms().then(() => {
        this.confirmLegal = false
      }).catch((error) => {
        console.error('Terms not accepted', error)
      })
    },
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_content";
@import "@/assets/scss/partials/_subscriptions";
</style>
