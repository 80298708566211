<template>
  <div>
    <DashboardHeader :activeTab="getActiveTab()" @tab="setActiveTabMethod"/>
    <Subscriptions v-if="getActiveTab() === 'subscriptions'"/>
    <Invoices v-if="getActiveTab() ==='invoices'"/>
  </div>
</template>

<script>
import DashboardHeader from '@/components/DashboardHeader'
import Subscriptions from '@/components/SubscriptionsComponent'
import Invoices from '@/components/InvoicesComponent'
import { mapActions, mapGetters, mapMutations } from 'vuex'
const FIRST_LOGIN = 'dashboard_visited'

export default {
  name: 'Dashboard',
  components: {
    DashboardHeader,
    Subscriptions,
    Invoices
  },
  beforeMount() {
    this.fetchInvoices()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    let firstLogin = localStorage.getItem(FIRST_LOGIN)

    if(!firstLogin && this.$route.name === 'Dashboard') {
      setTimeout(() => {
        this.setHighlightQR(true)
      }, 500)
      localStorage.setItem(FIRST_LOGIN, true)
    } else {
      this.setHighlightQR(false)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions([
      'fetchInvoices'
    ]),
    ...mapGetters([
      'getActiveTab',
    ]),
    ...mapMutations([
      'setActiveTab',
      'setHighlightQR'
    ]),
    setActiveTabMethod(tab) {
      this.setActiveTab(tab)
    }
  }
}
</script>
