<template>
  <div class="content-wrapper column" v-if="showSpinner">
      <Spinner/>
  </div>
  <div class="content-wrapper column" v-else>
    <div class="invoices-header padding-left-40">
      <span>{{$t('dashboard.header.invoices')}}</span>
      <div class="search">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.11" height="22.129" viewBox="0 0 22.11 22.129" class="magnifier">
          <g fill="none" stroke="#383838" stroke-miterlimit="10" transform="translate(.5 .5)">
            <circle cx="9" cy="9" r="9"></circle>
            <path stroke-linecap="square" d="M0 0l5.4 5.422" transform="translate(15.5 15.5)"></path>
          </g>
        </svg>
        <input type="text" :placeholder="$t('dashboard.recordings.search_for_invoices')" v-model="searchFilter">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="clear-filter" v-if="searchFilter.length > 0" @click="searchFilter = ''">
          <path d="M24 1.414L22.586 0 12 10.586 1.414 0 0 1.414 10.586 12 0 22.586 1.414 24 12 13.414 22.586 24 24 22.586 13.414 12z"></path>
        </svg>
      </div>
    </div>
    <div class="mobile-invoices" v-if="paginatedInvoices.length > 0 && isWeb()">
      <div v-for="invoice in paginatedInvoices" :key="invoice.id" :class="['invoice', {'expand' : expandedInvoice && expandedInvoice.id === invoice.id}]">
        <div class="invoice-header">
          <span>{{$t('dashboard.invoices.invoice')}}</span>
          <img src="@/assets/images/more.svg" :alt="$t('dashboard.invoices.more')" @click="expandInvoice(invoice)" :class="{'expanded': expandedInvoice && expandedInvoice.id === invoice.id}">
        </div>
        <div class="invoice-details">
          <span class="invoice-label">{{invoice.id}}</span>
          <span class="invoice-date">{{getDate(invoice.payed)}}</span>
          <div class="invoice-info-label">{{$t('dashboard.invoices.description')}}</div>
          <div class="invoice-info-data">
            {{ invoice.description === 'annual' ? $t('funnel.annual') : $t('funnel.monthly')}} {{$t('dashboard.invoices.subscription')}}
          </div>
          <div class="invoice-info-label">{{$t('dashboard.invoices.total')}}</div>
          <div class="invoice-info-data">
            <b>${{invoice.total}}</b>
          </div>
          <div class="invoice-info-label">{{$t('dashboard.recordings.status')}}</div>
          <div class="invoice-info-data">
            <span class="invoice-due paid"><img class="thumb-up" src="@/assets/images/thumb.svg" :alt="$t('dashboard.invoices.paid')">{{$t('dashboard.invoices.paid')}} {{$t('dashboard.invoices.on')}} {{ getDate(invoice.payed)}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-invoices" v-if="paginatedInvoices.length === 0 && getActiveSubscriptions()[0] && isWeb()">
      <div class="invoice-header">
          <span>{{$t('dashboard.recordings.no_recordings_found')}}</span>
      </div>
    </div>
    <div class="mobile-invoices" v-if="getActiveSubscriptions()[0] && !isWeb()">
      <div class="invoice-header">
          <span>{{$t('dashboard.invoices.if_you_want')}}</span>
      </div>
    </div>
    <table class="invoices">
      <thead>
        <tr>
          <td><span class="padding-left-40">{{$t('dashboard.invoices.invoice')}}</span></td>
          <td>{{$t('dashboard.invoices.description')}}</td>
          <td>{{$t('dashboard.invoices.total')}}</td>
          <td>{{$t('dashboard.recordings.status')}}</td>
        </tr>
      </thead>
      <tbody v-if="paginatedInvoices.length > 0 && isWeb()">
        <tr v-for="invoice in paginatedInvoices" :key="invoice.id">
          <td>
            <span class="invoice-label padding-left-40">{{invoice.id}}</span>
            <span class="invoice-date padding-left-40">{{getDate(invoice.payed)}}</span>
          </td>
          <td>
            <span class="invoice-description" v-if="invoice.description === 'yearly'">{{$t('funnel.annual')}} {{$t('dashboard.invoices.subscription')}}</span>
            <span class="invoice-description" v-else>{{$t('funnel.monthly')}} {{$t('dashboard.invoices.subscription')}}</span>
          </td>
          <td>
            <span class="invoice-price">${{invoice.total}}</span>
          </td>
          <td>
            <span class="invoice-due paid"><img class="thumb-up" src="@/assets/images/thumb.svg" :alt="$t('dashboard.invoices.paid')">{{$t('dashboard.invoices.paid')}} {{$t('dashboard.invoices.on')}} {{ getDate(invoice.payed)}}</span>
          </td>
        </tr>
      </tbody>
      <tbody v-if="paginatedInvoices.length === 0 && isWeb()">
        <tr class="no-data">
          <td colspan="12">{{$t('dashboard.recordings.no_recordings_found')}}</td>
        </tr>
      </tbody>
      <tbody v-if="!isWeb() && !backendError">
        <tr class="no-data">
          <td colspan="12">{{$t('dashboard.invoices.if_you_want')}}</td>
        </tr>
      </tbody>

      <tbody v-if="!isWeb() && backendError">
        <tr class="no-data">
          <td colspan="12">{{$t('dashboard.backend_error')}}</td>
        </tr>
      </tbody>
    </table>

    <div class="pagination" v-if="pagination > 0">
      <span @click="currentPage = currentPage - 1;" v-if="currentPage > 1">&lt;</span>

      <div v-if="pagination < 11">
        <span v-for="(page, index) in pagination" :key="'page-' + index" :class="{'active' : currentPage === page}" @click="currentPage = page;">{{ page }}</span>
      </div>
      <div v-else>
        <span v-if="currentPage !== 1" @click="currentPage = 1;">1</span>
        <span v-if="currentPage > 2">...</span>
        <span @click="currentPage = currentPage - 1;" v-if="(currentPage -1) > 2">{{ currentPage - 1 }}</span>
        <span class="active">{{ currentPage }}</span>
        <span @click="currentPage = currentPage + 1;" v-if="(currentPage + 1) < pagination - 1">{{ currentPage + 1 }}</span>
        <span v-if="(currentPage + 1) < pagination">...</span>
        <span @click="currentPage = pagination;" v-if="currentPage !== pagination">{{ pagination }}</span>
      </div>

      <span @click="currentPage = currentPage + 1;" v-if="currentPage < pagination">&gt;</span>
    </div>

  </div>
</template>

<script>
import Spinner from '@/components/Spinner'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Invoices',
  components: {
    Spinner
  },
  data() {
    return {
      searchFilter: '',
      currentPage: 1,
      pageSize: 10,
      expandedInvoice: null,
      showSpinner: true,
      backendError: false
    }
  },
  computed: {
    filteredInvoices() {
      return this.getInvoices().sort((a, b) => {return b.id - a.id}).filter((entry) => entry.id.toLowerCase().indexOf(this.searchFilter.toLowerCase()) !== -1)
    },
    paginatedInvoices() {
      return this.filteredInvoices.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    pagination() {
      let pages = this.filteredInvoices.length / this.pageSize
      if(this.isInt(pages)) {
        return pages
      }
      return Math.floor(pages) + 1
    }
  },
  mounted() {
    this.fetchSubscriptions().then(() => {
      this.showSpinner = false
    }).catch(() => {
      this.showSpinner = false
    })
  },
  methods: {
    ...mapActions([
      'fetchSubscriptions'
    ]),
    ...mapGetters([
      'getInvoices',
      'getActiveSubscriptions'
    ]),
    isWeb() {
      let activeSub = this.getActiveSubscriptions()[0]

      if(!activeSub) {
        this.backendError = true
        return
      }

      let skuu = activeSub.product_id

      if(skuu.indexOf('bt-trec') !== -1) {
        return true
      }

      return false
    },
    expandInvoice(invoice) {
      if(this.expandedInvoice && this.expandedInvoice.id === invoice.id) {
        this.expandedInvoice = null
        return
      }

      this.expandedInvoice = invoice
    },
    isInt(n) {
       return n % 1 === 0
    },
    getDate(date) {
      return new Date(date).toISOString().substring(0, 10);
    },
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_content";
@import "@/assets/scss/partials/_invoices";
</style>
